/* eslint-disable import/order */
/* eslint-disable react/jsx-filename-extension */
import { SEO } from "@components/seo";
import React from "react";
import Container from "react-bootstrap/Container";
import { isMobile } from "react-device-detect";
import Layout from "../components/layout";
import "../styles/pages/_page404.scss";
import Cursor from "./../components/Cursor/cursor";
import Section from "./../components/_SectionTemplate";
import useLocoScroll from "./../components/_hooks/useLocoScroll";
import { NotFound } from './../components/_pages/page404/NotFound';

function NotFoundPage() {
  useLocoScroll(true);

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <Layout>
      {!isMobile && <Cursor />}
      <SEO />
      <Container fluid>
        <Section desc="lost" hero>
          <NotFound />
        </Section>
      </Container>
    </Layout>
  );
}

export default NotFoundPage;
